<template>
    <div :class="{ 'input-group company-picker': true, 'input-group-sm': small }">
        <dx-autocomplete
            ref="dxComponent"
            :class="{ 'form-control': true, 'form-control-sm': small }"
            :drop-down-options="{ minWidth: 350 }"
            :placeholder="inputPlaceholder"
            :data-source="dataSource"
            :disabled ="isDisabled"
            value-expr="displayName"
            :show-clear-button="showClearButton"
            :min-search-length="1"
            :input-attr="inputAttr"
            :item-template="itemTemplate"
            :value="currentValue.displayName"
            v-model:text="searchText"
            @valueChanged="onValueChanged"
            @selectionChanged="onSelectionChanged"
            @focusIn="onFocusIn"
            @focusOut="onFocusOut"
            @keyDown="onKeyDown"
            @keyUp="onKeyUp"
            @opened="onOpened"
        />
        <b-dropdown v-if="dropDownActive"
            :disabled="disabled"
            @click="onAdvancedSearchClick"
            right split>
            <template #button-content><FontAwesomeIcon icon="fas fa-search" aria-hidden="true" /></template>
            <b-dropdown-item v-show="currentValue.companyID"
                @click="onEditCompanyClick">View/Edit Selected Company</b-dropdown-item>
            <b-dropdown-item v-show="canAddOneTimeUseCompany"
                @click="onOneTimeUseClick">Create One-Time Use</b-dropdown-item>
        </b-dropdown>
        <b-btn v-else
            v-rq-tooltip.hover.top
            title="Search Companies"
            variant="theme"
            :disabled="disabled"
            @click="onAdvancedSearchClick">
            <FontAwesomeIcon icon="fa fa-search" aria-hidden="true" />
        </b-btn>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { closeDropDownOnDialogMouseEvent } from "vendor/rq-devextreme-vue.esm";
    import CompanyContactLookup from "@order-entry/contacts/components/CompanyContactLookup";
    import CompanyEdit from "@utilities/manage-companies/components/CompanyEdit";
    import { CompanyPickerModel, SearchRequest, SortOption } from "@/shared/models/models";
    import { RoleType } from "@/shared/models/enums";

    export default {
        name: "CompanyPicker",
        emits: [ "change", "changeName", "focusIn", "focusOut", "update:companyId", "update:text", "update:modelValue" ],
        props: {
            automation_id: {
                default: "",
                type: String
            },
            fieldName: null,
            modelValue: null,
            companyId: { type: Number, default: 0 },
            companyName: null,
            companyContactId: { type: Number, default: 0 },
            dialogTitle: { default: '' },
            disabled: { default: false },
            companyRoleName: { default: '' },
            companyRoleId: { type: Number, default: 0 },
            showClearButton: { default: true },
            showContacts: { default: true },
            inputPlaceholder: { default:"Search..." },
            noDropDown: { type: Boolean, default: false },
            small: { type: Boolean, default: false },
            showSelectionSummary: {type: Boolean, default: false},
            autoResolve: {type: Boolean, default: false},
            text: { type: String, default: null },
            companyRegionId: { type: Number, default: 0 },
        },

        data () {
            const self = this;
            return {
                currentValue: new CompanyPickerModel(),
                previousValue: new CompanyPickerModel(),
                currentSearchData: [],
                lastValue: null,
                lastValidValue: null,
                isFocused: false,
                isSearching: false,
                currentTotalCount: 0,
                skip: 0,
                take: 10,
                pageSize: 10,
                inputAttr: {
                    automation_id: this.automation_id,
                    autocomplete: Math.random()
                },
                skipNextFetch: false,
                searchText: null,
                internalDisabled: false,
                initialized: false,
                ignoreSelectionChanged: false,
                valueResolvedByTab: false
            };
        },

        computed: {
            ...mapState({
                order: state => state.orders.order,
            }),
            hasValue() { return _.parseNumber(this.currentValue.companyID, 0) > 0; },
            isValid() { return this.currentValue?.isValid; },
            canAddOneTimeUseCompany(){ return _.parseBool(this.securitySettings.findValue("AllowOneTimeUseAgent")) && !this.currentValue.isValid; },
            dropDownActive() { return !this.noDropDown && (this.hasValue || this.canAddOneTimeUseCompany); },
            theme() { return _.get(this, "$route.meta.theme", "default") || "default"; },
            isOrderRoute() { return _.some(_.get(this, "$route.matched"), { name: "Order" }); },
            isDisabled() { return this.disabled || this.internalDisabled; }
        },

        watch: {
            searchText(newVal, oldVal) {
                if(newVal === oldVal) return;
                this.$emit("update:text", newVal);
                this.fetchData();
            },
            modelValue(newVal, oldVal) {
                if(this.compare(newVal, oldVal)) return;
                this.refreshCurrentValue(newVal);
            }
        },

        created() {
            const self = this;

            if(!_.isNil(self.companyName) || _.parseNumber(self.companyContactId, 0) > 0) {
                console.warn("RQ CompanyPicker :: Individual company props have been deprecated.  Pass those values in an object to v-model or prop \"modelValue\" instead.  e.g. { companyID, companyName, contactID, contactName }");
            }

            self.refreshCurrentValue();
            self.currentSearchData = [];
            self.dataSource = {
                key: "companyID",
                group: "role",
                byKey: key => (_.parseNumber(key,0) > 0
                    ? { companyID: key, name: key === self.currentValue.companyID ? self.currentValue.companyName : "" }
                    : null),
                load() {
                    return Promise.resolve({
                        data: self.currentSearchData.slice(),
                        totalCount: self.currentTotalCount
                    });
                }
            };
            self.initialized = true;
        },

        methods: {
            fetchData: _.debounce(function() {
                const self = this;
                let searchTerm = self.searchText;
                if(_.isEmpty(searchTerm) || searchTerm === self.currentValue?.displayName || this.skipNextFetch) {
                    this.skipNextFetch = false;
                    return;
                }

                let searchRequest = new SearchRequest({
                    searchTerm,
                    skip: self.skip,
                    take: self.take,
                    parameters: { activeOnly: true, specificRegionOnly: false }
                });
                let searchParts = _.split(searchTerm, " - ");
                let searchId = _.parseNumber(searchTerm, 0);

                if(searchParts.length > 1 && !_.isNil(self.lastValidValue) && _.parseNumber(searchParts[0], null) === self.lastValidValue.companyID) {
                    searchParts.shift();
                    searchRequest.searchTerm = _.join(searchParts, " - ");
                }

                if(_.gt(searchId, 0)) {
                    searchRequest.searchTerm = null;
                    searchRequest.addFilter("CompanyID", "=", searchId);
                }

                if(!_.isEmpty(searchRequest.searchTerm)) {
                    searchRequest.searchFields = ["Name", "FriendlyName"];
                }

                if(_.gt(self.companyRoleId, 0)) {
                    let RoleColumn = (self.companyRoleId === RoleType.PlaceOfClosing ? "isPlaceOfClosing" :
                        self.companyRoleId === RoleType.SettlementAgent ? "isSettlementAgent" : "");

                    if(!_.isEmpty(RoleColumn))
                    {
                        let roleFilter = [
                            ["RoleTypeID", "=", self.companyRoleId],
                            "OR",
                            [RoleColumn, "=", 1]
                        ];
                        searchRequest.appendFilter(roleFilter);
                    }
                    else
                        searchRequest.addFilter("RoleTypeID", "=", self.companyRoleId);
                }

                if(_.gt(self.companyRegionId, 0)){
                    searchRequest.parameters["regionId"] = self.companyRegionId;
                    searchRequest.parameters["specificRegionOnly"] = true;
                } else if(self.isOrderRoute) {
                    searchRequest.parameters["regionId"] = self.order.regionID;
                    searchRequest.parameters["specificRegionOnly"] = true;
                }

                self.isSearching = true;

                return self.$api.CompaniesApi.search(searchRequest)
                    .then(response => {
                        let currentResults = _.map(response.results, r => new CompanyPickerModel(r));
                        if(self.skip > 0) {
                            _.last(self.currentSearchData).hasMore = false;
                            self.currentSearchData.push(...currentResults);
                        }
                        else{
                            self.currentSearchData = currentResults;
                        }
                        self.currentTotalCount = response.totalRecords;
                        self.isSearching = false;

                        if (!self.isFocused) {
                            self.runSearchCheck();
                        }

                        if(_.lt(self.currentSearchData.length, response.totalRecords)){
                            _.last(self.currentSearchData).hasMore = true;
                        }
                        self.reloadDataSource();
                        if(self.currentTotalCount > 0) self.openDropDown();
                        return { data: self.currentSearchData, totalCount: self.currentTotalCount };
                    });
            }, 250, { leading: false, trailing: true }),

            onValueChanged (e) {
                this.resetPaging();
                if (e.value) return;
                this.setSelectedCompany(null);
            },

            onItemClick (e) {
                let selectedItem = _.get(e, "itemData", null);
                let companyItem = new CompanyPickerModel(selectedItem);
                this.setSelectedCompany(companyItem);
            },

            onSelectionChanged (e) {
                if(!e.selectedItem || this.ignoreSelectionChanged) return;
                let selectedItem = _.get(e, "selectedItem", null);
                let companyItem = new CompanyPickerModel(selectedItem);
                this.setSelectedCompany(companyItem);
            },

            onOpened(e) {
                const self = this;
                if(self.isFocused) return;
                self.closeDropDown();
            },

            onFocusIn (e) {
                this.valueResolvedByTab = false;
                this.isFocused = true;
                this.$emit("focusIn", { data: e, isValid: this.isValid });
            },

            onFocusOut (e) {
                if(!this.valueResolvedByTab) {
                    this.restoreDisplayValue();
                    closeDropDownOnDialogMouseEvent(e);
                }
                this.valueResolvedByTab = false;
                this.isFocused = false;
                this.$emit("focusOut", { data: e, isValid: this.isValid });
            },

            onAdvancedSearchClick(e) { this.showPicker(); },

            onEditCompanyClick(e) { this.showEditCompany(); },

            onOneTimeUseClick(e) {
                const self = this;
                let newCompany = {
                    companyInfo: {
                        companyID:0,
                        name: self.currentValue.companyName,
                        regionID: self.$store.state.orders.orderSummary.regionID,
                        isOneTimeUse: true,
                        roleTypeID: new Number(self.companyRoleId)
                    }
                };
                let apiPromise = self.$api.CompaniesApi.saveCompanyDetail(newCompany);
                self.$rqBusy.wait(apiPromise, {topLevel: true})
                    .then(company => {
                        self.setSelectedCompany(new CompanyPickerModel(company));
                    });
            },

            onKeyDown(e) {
                let isTabKey = e.event.key === "Tab";
                let isLookupShortcut = e.event.ctrlKey && e.event.key === "l";
                if(isTabKey) {
                    this.valueResolvedByTab = true;
                    if(this.currentSearchData.length === 0 || this.currentValue.companyID > 0) {
                        this.restoreDisplayValue();
                        return;
                    }
                    if(this.currentSearchData.length === 1)
                        this.setSelectedCompany(this.currentSearchData[0]);
                    else
                        this.runSearchCheck();
                    return;
                }
                if(!isLookupShortcut) return;
                this.stopEventPropagation(e.event);
                this.showPicker();
            },

            onKeyUp(e) {
                let isTabKey = e.event.key === "Tab";
                let isLookupShortcut = e.event.ctrlKey && e.event.key === "l";
                if(!isLookupShortcut && !isTabKey) return;
                this.stopEventPropagation(e.event);
            },

            stopEventPropagation(e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            },

            itemTemplate(data, itemIndex, itemElement) {
                const self = this;
                let reg = new RegExp(self.searchText, 'ig');
                let display = data.displayName.replace(reg, '<strong>$&</strong>');

                itemElement.append(
                    $('<span>').addClass('d-flex justify-content-between')
                        .append($('<span>').append(display))
                        .append($('<span>').addClass('rq-pkr-role-name').append(data.role)));

                if(self.skip > 0 && itemIndex + 1 === self.currentSearchData.length) {
                    self.$nextTick(()=>{
                        let $scrollView = itemElement.closest(".dx-scrollview");
                        if(!$scrollView) return;
                        let $instance = $scrollView.dxScrollView("instance");
                        $instance.scrollTo($instance.scrollHeight());
                    });
                }

                if(!_.parseBool(data.hasMore)) return;

                itemElement.parent().after(
                    $("<div/>")
                        .addClass("text-center")
                        .append(
                            $("<button>")
                                .attr("automation_id", "btn_more")
                                .attr("type", "button")
                                .addClass("btn btn-sm btn-link p-0")
                                .text("More...")
                                .on("click", function () {
                                    $(this).unbind();
                                    self.getMore();
                                })
                        )
                );
            },

            getAutoResolveKey(val={}) {
                if(this.initialized && val.companyID > 0) return val.companyID

                let key = _.getNumber(val, "companyID", null) || 0;
                if(!this.initialized && key === 0) key = this.companyId;
                return key;
            },

            refreshCurrentValue(val) {
                const self = this;
                let cdata = val || self.modelValue;
                let newValue = new CompanyPickerModel(cdata);
                let resolveKey = !self.initialized && _.isNil(newValue.companyID) ? self.companyId : newValue.companyID;
                if(!self.autoResolve || newValue.isValid || _.parseNumber(resolveKey, 0) === 0) {
                    self.finalizeCurrentValue(newValue, cdata);
                    return Promise.resolve(newValue);
                }
                self.internalDisabled = true;
                return self.$api.CompaniesApi.getCompany(resolveKey)
                    .then(result => {
                        newValue = new CompanyPickerModel(result);
                        self.finalizeCurrentValue(newValue, cdata);
                        self.internalDisabled = false;
                        return newValue;
                    });
            },

            finalizeCurrentValue(resolvedVal, lastVal) {
                this.skipNextFetch = resolvedVal.displayName !== this.currentValue.displayName;
                this.currentValue = resolvedVal;
                this.lastValue = new CompanyPickerModel(lastVal);
                this.syncCompanyId(this.currentValue.companyID);
                if(!this.lastValue.isValid) return;
                this.lastValidValue = new CompanyPickerModel(lastVal);
            },

            syncCompanyId(val) {
                if(this.companyId === val) return;
                this.$emit("update:companyId", val);
            },

            runSearchCheck () {
                const self = this;
                if (self.isValid || self.isSearching || _.isEmpty(self.currentSearchData)) return;

                //first check if it was entered as an ID
                let currentValAsId = _.parseNumber(self.currentValue.companyName, 0);
                let exactMatch = currentValAsId === 0 ? null : _.find(self.currentSearchData, r => r.companyID === currentValAsId);

                //then if that didn't return anything check if the name matches
                if(!exactMatch) exactMatch = _.find(self.currentSearchData, r => self.currentValue.matchesText(r.companyName));

                //then if there's still no match return
                if (!exactMatch) return;

                //update set the match to the current value
                self.setSelectedCompany(exactMatch);
            },

            setSelectedCompany(companyItem) {
                let newVal = companyItem instanceof CompanyPickerModel ? companyItem : new CompanyPickerModel(companyItem);

                if((this.currentValue.matchesText(newVal.companyName) && !_.isNil(this.currentValue.companyID) && _.isNil(newVal.companyID))
                    || this.compare(newVal, this.lastValue)) return false;

                newVal.previousValue = this.currentValue;
                this.currentValue = newVal;

                if(!this.$el) return false;

                this.ignoreSelectionChanged = true;
                this.lastValue = new CompanyPickerModel(this.currentValue);
                if(this.lastValue.isValid)
                    this.lastValidValue = new CompanyPickerModel(this.currentValue);

                this.emitValue(this.currentValue);

                this.syncCompanyId(this.currentValue.companyID);
                this.resetFocus();
                this.$nextTick(() => {
                    this.ignoreSelectionChanged = false;
                });
                return true;
            },

            showEditCompany() {
                const self = this;

                let okHandler = function (e, result) {
                    if (!result)
                        return false;

                    const resultEvent = _.get(e, "component", e);
                    var nameChanged = self.currentValue.companyName != resultEvent.companyInfo.name;

                    self.currentValue.companyName = resultEvent.companyInfo.name;
                    self.resetFocus();
                    if(nameChanged){
                        self.$emit("changeName", self.currentValue.companyName);
                    }

                    self.currentValue.lastPossibleEdit = new Date();
                    self.emitValue(self.currentValue);
                    return true;
                };

                self.closeDropDown();
                self.$dialog.open({
                    title: "Edit Company",
                    height: "85%",
                    width: "85%",
                    adaptive: false,
                    component: CompanyEdit,
                    scrollable: false,
                    props: {
                        companyId: self.currentValue.companyID,
                        modalMode: true
                    },
                    onOk (e) {
                        return e.component.modalSave().then((result) => okHandler(e, result));
                    },
                    onCancel (e) {
                        self.resetFocus();
                        return true;
                    }
                });
            },

            showPicker (company) {
                const self = this;
                let okHandler = function (e) {
                    let result = _.get(e, "originalEvent.data", e.data);
                    if (!result)
                        return false;

                    self.setSelectedCompany(result);
                    self.resetFocus();
                    let focusReset = self.setSelectedCompany(result);
                    if(!focusReset) self.resetFocus();
                    return true;
                };

                let dialogArgs = {
                    pickerSearchTerm: self.currentValue.companyID > 0 ? "" : self.searchText,
                    roleTypeId: self.companyRoleId,
                    selectedCompanyId: self.currentValue.companyID || 0,
                    selectedContactId: self.currentValue.contactID || 0,
                    showSelectionSummary: self.showSelectionSummary,
                    companyDisplayID: (self.currentValue.companyID > 0 ) ? self.currentValue.companyID.toString() : "",
                    companyDisplay: self.currentValue.companyName,
                    contactDisplay: self.currentValue.contactName,
                    specificRegionOnly: false
                };

                if(_.gt(self.companyRegionId, 0)){
                    dialogArgs["regionId"] = self.companyRegionId;
                    dialogArgs["specificRegionOnly"] = true;
                } else if(self.isOrderRoute) {
                    dialogArgs["regionId"] = self.order.regionID;
                    dialogArgs["specificRegionOnly"] = true;
                }

                self.closeDropDown();
                self.$dialog.open({
                    title: self.dialogTitle,
                    height: "90%",
                    width: "85%",
                    component: CompanyContactLookup,
                    props: dialogArgs,
                    closeOnEsc: true,
                    onOk (e) {
                        return okHandler(e);
                    },
                    onCancel (e) {
                        self.resetFocus();
                        self.currentValue.lastPossibleEdit = new Date();
                        self.emitValue(self.currentValue);
                        return true;
                    }
                });
            },

            getMore(){
                const self = this;
                self.skip += self.pageSize;
                self.take = self.currentTotalCount >= (self.skip + self.pageSize)
                    ? self.pageSize
                    : self.currentTotalCount - self.skip;
                self.fetchData();
            },

            resetPaging(){
                const self = this;
                self.skip = 0;
                self.take = self.pageSize;
                self.currentTotalCount = 0;
                self.currentSearchData = [];
            },

            reloadDataSource(){
                let ds = _.invoke(this, "$refs.dxComponent.instance.getDataSource");
                _.invoke(ds, "reload");
            },

            compare(obj1, obj2) {
                return _.isEqual(_.toPlainObject(obj1), _.toPlainObject(obj2));
            },

            formatDisplayName(itemData) {
                var reg = new RegExp(this.searchText, 'ig');
                return _.replace(itemData.displayName, reg, '<strong>$&</strong>');
            },

            restoreDisplayValue() {
                let displayValue = this.currentValue.companyID <= 0 || _.isEmpty(this.currentValue?.displayName)
                    ? ""
                    : this.currentValue?.displayName;
                if(this.searchText === displayValue) return;
                _.invoke(this, "$refs.dxComponent.instance.option", "value", displayValue);
            },

            closeDropDown() {
                _.invoke(this, "$refs.dxComponent.instance.close");
            },

            openDropDown() {
                _.invoke(this, "$refs.dxComponent.instance.open");
            },

            resetFocus(){
                this.$nextTick(() => {
                    _.invoke(this, "$refs.dxComponent.instance.focus");
                });
            },

            emitValue(val) {
                if(val === this.modelValue) return;
                this.$emit("change", val);
                this.$emit("update:modelValue", val);
            }
        }
    };
</script>
